body {
	font-size: 1.3em;
}

#gutters {
	/* container */
	display: grid;
	grid-template-columns: auto [main-start] 900px [main-end] auto;
}

#kernel {
	/* item */
	grid-column: main-start / main-end;

	/* container */
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 20px [toolbar-start] auto [toolbar-end heading-start] auto [heading-end main-start] auto [main-end footer-start] auto [footer-end];
}

#toolbar {
	/* item */
	grid-row: toolbar-start / toolbar-end;

	/* container */
	display: grid;
	grid-template-columns: [left-start] auto [left-end] auto [right-start] auto [right-end];
	justify-content: end;
}

#discord-button {
	/* item */
	grid-column: right-start / right-end;

	/* container */
	display: grid;
	align-items: center;
	grid-template-columns: [image-start] auto [image-end] 10px [text-start] auto [text-end];

	cursor: pointer;
}

#discord-button-image {
	grid-column: image-start / image-end;
	width: 50px;
}


#discord-button-text {
	grid-column: text-start / text-end;
}

#heading {
	/* item */
	grid-row: heading-start / heading-end;

	/* container */
	display: grid;
	grid-template-columns: [image-start] 350px [image-end text-start] auto [text-end];
	align-items: center;
}

#heading-image {
	/* item */
	grid-column: image-start / image-end;
}

#heading-image-image {
	width: 350px;
}

#heading-body {
	/* item */
	grid-column: text-start / text-end;

	/* container */
	display: grid;
	grid-template-rows: [title-start] auto [title-end tagline-start] auto [tagline-end];
}

#heading-body-title {
	/* item */
	grid-row: title-start / title-end;

	font-size: 2.5em;
}

#heading-body-tagline {
	/* item */
	grid-row: tagline-start / tagline-end;

	font-size: 1.5em;
}

#main {
	/* item */
	grid-row: main-start / main-end;

	/* container */
	display: grid;
	grid-auto-flow: row;
}

#footer {
	/* item */
	grid-row: footer-start / footer-end;

	/* container */
	display: grid;
	align-items: center;
	grid-template-columns: [text-start] auto [text-end] 8px [logo-start] auto [logo-end];
	justify-content: end;
}

#synthesis-logo-text {
	grid-coloumn: text-start / text-end;
	font-size: 0.9em;
}

#synthesis-logo-image {
	width: 130px;
}

#synthesis-logo {
	/* item */
	grid-column: logo-start / logo-end;
}

.section {
	/* item */

	/* container */
	display: grid;
	grid-template-rows: [section-title-start] auto [section-title-end section-body-start] auto [section-body-end] 20px;
}

.section-title {
	/* item */
	grid-row: section-title-start / section-title-end;

	font-size: 1.7em;
	background-color: #054653;
	padding: 2px 10px;
}

.section-body {
	/* item */
	grid-row: section-body-start / section-body-end;
}

#installation {
	/* container */
	display: grid;
	grid-template-columns: [installation-oses-start] 200px [installation-oses-end installation-text-start] auto [installation-text-end];

	padding: 10px 10px;
	min-height: 300px;
}

#installation-oses {
	/* item */
	grid-column: installation-oses-start / installation-oses-end;

	/* container */
	display: grid;
	grid-auto-flow: row;
	justify-content: right;
}

#installation-text {
	/* item */
	grid-column: installation-text-start / installation-text-end;
	padding: 10px;
	background-color: #2A3C42;
	margin-bottom: 10px;
	border-radius: 0px 10px 10px 0px;
}

.installation-text-pre {
	background-color: rgba(0, 0, 0, 0.25);
	padding: 10px;
	white-space: pre-wrap;
	word-wrap: break-word;
}

.installation-os {
	text-align: right;
	padding-right: 20px;
	margin-bottom: 10px;
	border-radius: 10px 0px 0px 10px;
	background-color: #25292E;
	width: 200px;
	font-size: 1.2em;
	cursor: pointer;
}

.installation-os.selected {
	background-color: #2A3C42;
}

#examples {
	/* container */
	display: grid;
	grid-auto-flow: row;
}

.example {
	/* container */
	display: grid;
	grid-template-rows: 10px [example-cmd-start] auto [example-cmd-end example-output-start] auto [example-output-end];
}

.example-cmd {
	/* item */
	grid-row: example-cmd-start / example-cmd-end;

	font-family: monospace;
	color: #ffffff;
}

.example-output {
	/* item */
	grid-row: example-output-start / example-output-end;

	font-family: monospace;
}

.example-output-pre {
	color: #25ac22;
	white-space: pre-wrap;
	word-wrap: break-word;
}

#faqs {
	/* container */
	display: grid;
	grid-auto-flow: row;
}

.faq {
	/* container */
	display: grid;
	grid-template-rows: 10px [faq-question-start] auto [faq-question-end faq-answer-start] auto [faq-answer-end];
}

.faq-question {
	/* item */
	grid-row: faq-question-start / faq-question-end;

	font-family: monospace;
	color: #ffffff;
	cursor: pointer;
}

.faq-answer {
	/* item */
	grid-row: faq-answer-start / faq-answer-end;

	font-family: monospace;
	color: #DC8E2C;
}

.faq-answer.hidden {
	display: none;
}