html, body, .root {
  height: 100px;
  color: #fff;
  background-color: #181a1c;
}

.wrapper {
  grid-gap: 10px;
  color: #444;
  height: 100%;
  grid-template: "header header" 100px
                 "sidebar content" 1fr
                 / 1fr 3fr;
  display: grid;
}

.header {
  text-align: center;
  grid-area: header;
  padding: 20px;
  font-size: 30px;
}

.sidebar {
  grid-area: sidebar;
  padding: 10px;
  font-size: 20px;
}

.content {
  grid-area: content;
  padding: 10px;
  font-size: 20px;
}

* {
  color: #fff;
}

body {
  font-family: Hanken Grotesk, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-weight: 900;
}

ul {
  margin-left: 20px;
}

.logo {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.css-button-3d--sand {
  min-width: 130px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  outline: none;
  padding: 5px 10px;
  font-weight: bold;
  transition: all .3s;
  display: inline-block;
  position: relative;
  box-shadow: 0 5px #adb5bd;
  background: #ced4da !important;
  border: none !important;
  border-radius: 5px !important;
}

.css-button-3d--sand:active, .css-button-3d--sand:hover, .css-button-3d--sand.active {
  color: #fff !important;
  background: #3a86ff !important;
  box-shadow: 0 5px #43f !important;
}

.css-button-3d--green {
  min-width: 130px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  background: #5865f2;
  border: none;
  border-radius: 5px;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  font-weight: bold;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  position: relative;
  box-shadow: 0 5px #7289da;
}

.css-button-3d--green:active, .css-button-3d--green:hover {
  background: #7289da !important;
}

.css-button-3d--green > img {
  width: 30px;
}

body {
  font-size: 1.3em;
}

#gutters {
  grid-template-columns: auto[main-start] 900px[main-end] auto;
  display: grid;
}

#kernel {
  grid-column: main-start / main-end;
  grid-template-rows: 20px[toolbar-start] auto[toolbar-end heading-start] auto[heading-end main-start] auto[main-end footer-start] auto[footer-end];
  grid-template-columns: auto;
  display: grid;
}

#toolbar {
  grid-row: toolbar-start / toolbar-end;
  grid-template-columns: [left-start] auto[left-end] auto[right-start] auto[right-end];
  justify-content: end;
  display: grid;
}

#discord-button {
  cursor: pointer;
  grid-column: right-start / right-end;
  grid-template-columns: [image-start] auto[image-end] 10px[text-start] auto[text-end];
  align-items: center;
  display: grid;
}

#discord-button-image {
  width: 50px;
  grid-column: image-start / image-end;
}

#discord-button-text {
  grid-column: text-start / text-end;
}

#heading {
  grid-row: heading-start / heading-end;
  grid-template-columns: [image-start] 350px[image-end text-start] auto[text-end];
  align-items: center;
  display: grid;
}

#heading-image {
  grid-column: image-start / image-end;
}

#heading-image-image {
  width: 350px;
}

#heading-body {
  grid-column: text-start / text-end;
  grid-template-rows: [title-start] auto[title-end tagline-start] auto[tagline-end];
  display: grid;
}

#heading-body-title {
  grid-row: title-start / title-end;
  font-size: 2.5em;
}

#heading-body-tagline {
  grid-row: tagline-start / tagline-end;
  font-size: 1.5em;
}

#main {
  grid-row: main-start / main-end;
  grid-auto-flow: row;
  display: grid;
}

#footer {
  grid-row: footer-start / footer-end;
  grid-template-columns: [text-start] auto[text-end] 8px[logo-start] auto[logo-end];
  justify-content: end;
  align-items: center;
  display: grid;
}

#synthesis-logo-text {
  grid-coloumn: text-start / text-end;
  font-size: .9em;
}

#synthesis-logo-image {
  width: 130px;
}

#synthesis-logo {
  grid-column: logo-start / logo-end;
}

.section {
  grid-template-rows: [section-title-start] auto[section-title-end section-body-start] auto[section-body-end] 20px;
  display: grid;
}

.section-title {
  background-color: #054653;
  grid-row: section-title-start / section-title-end;
  padding: 2px 10px;
  font-size: 1.7em;
}

.section-body {
  grid-row: section-body-start / section-body-end;
}

#installation {
  min-height: 300px;
  grid-template-columns: [installation-oses-start] 200px[installation-oses-end installation-text-start] auto[installation-text-end];
  padding: 10px;
  display: grid;
}

#installation-oses {
  grid-column: installation-oses-start / installation-oses-end;
  grid-auto-flow: row;
  justify-content: right;
  display: grid;
}

#installation-text {
  background-color: #2a3c42;
  border-radius: 0 10px 10px 0;
  grid-column: installation-text-start / installation-text-end;
  margin-bottom: 10px;
  padding: 10px;
}

.installation-text-pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #00000040;
  padding: 10px;
}

.installation-os {
  text-align: right;
  width: 200px;
  cursor: pointer;
  background-color: #25292e;
  border-radius: 10px 0 0 10px;
  margin-bottom: 10px;
  padding-right: 20px;
  font-size: 1.2em;
}

.installation-os.selected {
  background-color: #2a3c42;
}

#examples {
  grid-auto-flow: row;
  display: grid;
}

.example {
  grid-template-rows: 10px[example-cmd-start] auto[example-cmd-end example-output-start] auto[example-output-end];
  display: grid;
}

.example-cmd {
  color: #fff;
  grid-row: example-cmd-start / example-cmd-end;
  font-family: monospace;
}

.example-output {
  grid-row: example-output-start / example-output-end;
  font-family: monospace;
}

.example-output-pre {
  color: #25ac22;
  white-space: pre-wrap;
  word-wrap: break-word;
}

#faqs {
  grid-auto-flow: row;
  display: grid;
}

.faq {
  grid-template-rows: 10px[faq-question-start] auto[faq-question-end faq-answer-start] auto[faq-answer-end];
  display: grid;
}

.faq-question {
  color: #fff;
  cursor: pointer;
  grid-row: faq-question-start / faq-question-end;
  font-family: monospace;
}

.faq-answer {
  color: #dc8e2c;
  grid-row: faq-answer-start / faq-answer-end;
  font-family: monospace;
}

.faq-answer.hidden {
  display: none;
}



/*# sourceMappingURL=index.bb1a083a.css.map */
