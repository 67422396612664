* {
  color: white;
}

body {
  font-family: "Hanken Grotesk", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
  text-transform: uppercase;
}

ul {
  margin-left: 20px;
}

.logo {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.css-button-3d--sand {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 5px !important;
  border: none !important;
  background: #ced4da !important;
  box-shadow: 0 5px #adb5bd;
}

.css-button-3d--sand:active,
.css-button-3d--sand:hover,
.css-button-3d--sand.active {
  background: #3a86ff !important;
  box-shadow: 0 5px #4433ff !important;
  color: white !important;
}

.css-button-3d--green {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 5px;
  border: none;
  background: #5865f2;
  box-shadow: 0 5px #7289da;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.css-button-3d--green:active,
.css-button-3d--green:hover {
  background: #7289da !important;
}

.css-button-3d--green>img {
  width: 30px;
}