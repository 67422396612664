html,
body,
.root {
  height: 100;
  background-color: #181a1c;
  color: white;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas:
    "header header"
    "sidebar content";
  grid-gap: 10px;
  color: #444;
  grid-template-rows: 100px 1fr;
  height: 100%;
}

.header {
  grid-area: header;
  padding: 20px;
  text-align: center;
  font-size: 30px;
}

.sidebar {
  grid-area: sidebar;
  padding: 10px;
  font-size: 20px;
}

.content {
  grid-area: content;
  padding: 10px;
  font-size: 20px;
}